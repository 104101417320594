import '../styles/Header.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../image/Logo-share.webp';
import Menushare from '../image/Menu-share.webp';
import Profil from '../components/Profil'
import Carousel from "../components/Carousel";
import Carou1 from "../components/Carou1";
import ToggleButton from '../components/ToggleButton';
import { useNavigate } from 'react-router-dom';



// Importez vos images statiques ici
import ImageBank from '../image/ImageBank.webp';
import ImageBankMobile from '../image/ImageBankMobile.webp';
import comptable from '../image/comptable.webp';
import comptableMobile from '../image/comptableMobile.webp';
import industrie from '../image/industrie.webp';
import industrieMobile from '../image/industrieMobile.webp';
import shareE1 from '../image/shareE1.webp';
import shareE1Mobile from '../image/shareE1Mobile.webp';


function Header() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 992);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Appel initial pour fixer l'état correct à la première exécution

    // Nettoyage de l'effet
    return () => window.removeEventListener('resize', handleResize);
  }, []);



    // État pour stocker le nom d'utilisateur
    const [username, setUsername] = useState(null);

    // Vérifiez si un token d'authentification est présent dans le localStorage
    const authToken = localStorage.getItem('authToken');
    console.log('Token récupéré du localStorage:', authToken);
    const isAuthenticated = authToken !== null;

    // Fonction pour gérer la déconnexion
    const handleLogout = () => {
        // Supprimez le token d'authentification du localStorage
        localStorage.removeItem('authToken');
    };

    // Fonction pour mettre à jour `username` depuis le composant `Profil`
  const updateUsername = (newUsername) => {
    setUsername(newUsername);
  };

    useEffect(() => {
      // Si l'utilisateur est authentifié, extrayez le nom d'utilisateur du token
      if (isAuthenticated) {
        try {
          // Divisez le token en ses parties (header, payload, signature)
          const tokenParts = authToken.split('.');
          console.log('Token divisé en parties:', tokenParts);
          // Décodage de la partie payload (qui est encodée en base64)
          const decodedPayload = JSON.parse(atob(tokenParts[1]));
          console.log('Token décodé:', decodedPayload);
    
          // Vérifiez si le payload a été correctement décodé et s'il contient un nom d'utilisateur
          if (decodedPayload && decodedPayload.username) {
            setUsername(decodedPayload.username);
          }
        } catch (error) {
          console.error('Erreur lors du décodage du token :', error);
        }
      }
    }, [isAuthenticated, authToken]);
    
  
    useEffect(() => {
      // Rafraîchir la liste des fichiers après le téléchargement réussi
      const refreshInterval = setInterval(() => {
          // Vérifiez si l'utilisateur est authentifié
          if (isAuthenticated && username) {
              // Utilisez le `username` mis à jour ici
              console.log(`L'utilisateur ${username} est authentifié et le rafraîchissement automatique est en cours.`);
          } else {
              console.log('L\'utilisateur n\'est pas authentifié, le rafraîchissement automatique est désactivé.');
          }
      }, 5000);
    
      return () => {
        clearInterval(refreshInterval);
      };
  }, [isAuthenticated, username]); // `username` doit être inclus ici
  

  const navigate = useNavigate();
  const logout = () => {
          localStorage.removeItem('authToken');
          navigate('/');
        }



// Définissez le tableau statique des images pour le Carousel, y compris la couverture
const carouselImages = [
  ImageBank,
  comptable,
  industrie,
  shareE1,
];
const carouImages1 = [
  ImageBankMobile,  // Chemin de l'image
  comptableMobile, // Chemin de l'image
  industrieMobile, // Chemin de l'image
  shareE1Mobile // Chemin de l'image
];

return (
  <>
    <header className='header'>
    <Link to='/' className='link'>
        <img src={logo} alt='entete' className='logo' />
      </Link>
      <div className='cadreExcellence'>
      <div className='header-toggle'>
        <ToggleButton
          title={
            <div className='hr'>
              <div className='Menu-share'>
                <img src={Menushare} alt='Menu' className='Menu-share1' />
              </div>
              
            </div>
          }
          text={
            <div>
              <nav className="nav">
                <Link to='/'><button type="button"><i class="fa-solid fa-house"></i></button></Link>
                {isAuthenticated ? (
                  <button type="button" className='green' onClick={handleLogout}>
                    {username ? `Bienvenue, ${username}, vous êtes maintenant connecté(e)` : 'Bienvenue, vous êtes maintenant connecté(e)'}
                  </button>
                ) : (
                  <Link to='/connect'><button type="button">Session</button></Link>
                )}
                <Link to='/propos'><button type="button">A Propos</button></Link>
                <Link to='/conditionGenerales'><button type="button">Condition Generale</button></Link>
                {isAuthenticated && <button onClick={logout}>Déconnexion</button>}  
              </nav>
            </div>
          }
         
        />
      </div>
      </div>
      <div className='monMurTitle1'><span className='accueil'> « Multiplier la productivité de vos collaborateurs »</span><br /> « Étendez votre réseau de clients, de partenaires, ou bien de fournisseurs »</div>
      {isDesktop ? (
        <Carousel slides={carouselImages} />
      ) : (
        <Carou1 diapo={carouImages1} />
      )}
  
      <div className='dessin10'>
        <i className="fa-solid fa-address-book"></i><p className="adress">50 avenue de la liberté, 94700 Maisons-alfort</p>
        <i className="fa-solid fa-phone-volume"></i><p className="phone"><a href="tel:+33765501408" className='tel'>
      Appeler maintenant
    </a></p>
        <i className="fa-regular fa-clock"></i><p className="hour">Du lundi au vendredi de 9h à 19h - Fermé le week-end</p>
      </div>
      
    </header>

    {isAuthenticated && <Profil className='profil-header' updateUsername={updateUsername} />}
  </>
);

}

export default Header;

