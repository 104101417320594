import "../styles/NotFound.css";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";


function Error() {

    return (
            <div className="container">
            <Header />
            <main>
            <div className="background30">
            
            <div className="quatre">404</div>
            <div className="noexist">Oops! La page que vous demandez n'existe pas</div>
            <div className="return"><Link to='/'>Retourner sur la page d’accueil</Link></div>
            </div>
            </main>
            <Footer />
            </div>
          
            )
}
export default Error;